import Axios from "axios";
const createApiFunctions = (baseURL: string, acceptLanguageValue?: string) => {
    const api = Axios.create({ withCredentials: true, baseURL, headers: acceptLanguageValue != null ? { "Accept-Language": acceptLanguageValue } : undefined });
    const apiFunctions = {
        Account: {
            ValidatePasswordFormatting: Object.assign(
                (password: server.dto.ValidatePasswordInstruction) => api.post<server.dto.Outcome>(`/api/v1/account/password/validateformatting`, password, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/account/password/validateformatting`, requestSizeLimit: 3145728 }
            ),
            ValidateMyPassword: Object.assign(
                (password: server.dto.ValidatePasswordInstruction) => api.post<boolean>(`/api/v1/account/validatemypassword`, password, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/account/validatemypassword`, requestSizeLimit: 3145728 }
            ),
            ChangeMyPassword: Object.assign(
                (request: server.dto.ChangePasswordRequest) => api.post<server.dto.Outcome>(`/api/v1/account/changemypassword`, request, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/account/changemypassword`, requestSizeLimit: 3145728 }
            ),
            ChangeMyName: Object.assign(
                (dto: server.dto.UpdateUserPersonalNameInstruction) => api.post<any>(`/api/v1/account/my/changename`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/account/my/changename`, requestSizeLimit: 3145728 }
            ),
            GetSecretQuestionItem: Object.assign(
                (questionId: number) => api.get<string>(`/api/v1/account/secretquestionitem`, { params: { questionId } }),
                { absoluteUrl: (questionId: number) => `${baseURL}/api/v1/account/secretquestionitem?questionId=${questionId}`, requestSizeLimit: 3145728 }
            ),
            GetCurrentSecretQuestion: Object.assign(
                () => api.get<number>(`/api/v1/account/currentsecretquestion`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/account/currentsecretquestion`, requestSizeLimit: 3145728 }
            ),
            GetCurrentSecretAnswer: Object.assign(
                () => api.get<string>(`/api/v1/account/currentsecretanswer`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/account/currentsecretanswer`, requestSizeLimit: 3145728 }
            ),
            ChangeSecretQuestion: Object.assign(
                (data: server.dto.UpdateSecretQuestionInstruction) => api.post<boolean>(`/api/v1/account/changesecretquestion`, data, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/account/changesecretquestion`, requestSizeLimit: 3145728 }
            )
        },
        Actions: {
            IsValidUrl: Object.assign(
                (url: string) => api.get<boolean>(`/api/v1/isvalidurl`, { params: { url } }),
                { absoluteUrl: (url: string) => `${baseURL}/api/v1/isvalidurl?url=${url}`, requestSizeLimit: 3145728 }
            )
        },
        AdminOne: {
            MigrateRecipients: Object.assign(
                () => api.get<string>(`/api/v1/adminone/MigrateRecipients`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/adminone/MigrateRecipients`, requestSizeLimit: 3145728 }
            ),
            CreateShowToDateRecords: Object.assign(
                (truncateExisting: boolean) => api.post<any>(`/api/v1/adminone/createshowtodaterecords`, undefined, { params: { truncateExisting } }),
                { absoluteUrl: (truncateExisting: boolean) => `${baseURL}/api/v1/adminone/createshowtodaterecords?truncateExisting=${truncateExisting}`, requestSizeLimit: 3145728 }
            ),
            RefreshExternalIdentities: Object.assign(
                (modifiedLongerThanHours: number) => api.post<string>(`/api/v1/adminone/updateexternalidentities`, undefined, { params: { modifiedLongerThanHours } }),
                { absoluteUrl: (modifiedLongerThanHours: number) => `${baseURL}/api/v1/adminone/updateexternalidentities?modifiedLongerThanHours=${modifiedLongerThanHours}`, requestSizeLimit: 3145728 }
            ),
            ListReprimands: Object.assign(
                () => api.get<server.dto.CheatingReprimand[]>(`/api/v1/adminone/Reprimands`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/adminone/Reprimands`, requestSizeLimit: 3145728 }
            )
        },
        Artists: {
            GetArtistPickerData: Object.assign(
                (dto: server.dto.GetArtistPickerDataInstruction) => api.post<server.dto.ArtistPickerData>(`/api/v1/artists/picker`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/artists/picker`, requestSizeLimit: 3145728 }
            ),
            ListUsersWithAccess: Object.assign(
                (artistId: number) => api.get<server.dto.UserThatCanAccessArtist[]>(`/api/v1/artists/${artistId}/UsersWithAccess`, undefined),
                { absoluteUrl: (artistId: number) => `${baseURL}/api/v1/artists/${artistId}/UsersWithAccess`, requestSizeLimit: 3145728 }
            ),
            GetArtistsForCurrentUserPaginated: Object.assign(
                (excludeIds: number[], keyword: string, page: number, per_page: number) => api.post<server.dto.PaginationWrapper<server.dto.LegacyArtist[]>>(`/api/v1/artists/myartists`, excludeIds, { params: { keyword, page, per_page } }),
                { absoluteUrl: (keyword: string, page: number, per_page: number) => `${baseURL}/api/v1/artists/myartists?keyword=${keyword}&page=${page}&per_page=${per_page}`, requestSizeLimit: 3145728 }
            ),
            ListAppointments: Object.assign(
                (artistId: number, timeMin: Date, timeMax: Date, page: number, per_page: number, appointmentTypes: server.dto.AppointmentTypes[]) => api.get<server.dto.PaginationWrapper<any[]>>(`/api/v1/artists/${artistId}/appointments`, { params: { timeMin, timeMax, page, per_page, appointmentTypes } }),
                { absoluteUrl: (artistId: number, timeMin: Date, timeMax: Date, page: number, per_page: number, appointmentTypes: server.dto.AppointmentTypes[]) => `${baseURL}/api/v1/artists/${artistId}/appointments?timeMin=${timeMin}&timeMax=${timeMax}&page=${page}&per_page=${per_page}&appointmentTypes=${appointmentTypes}`, requestSizeLimit: 3145728 }
            )
        },
        Companies: {
            GetEmails: Object.assign(
                (companyId: number, page: number, per_page: number) => api.get<server.dto.PaginationWrapper<server.dto.EmailMessage[]>>(`/api/v1/companies/${companyId}/emails`, { params: { page, per_page } }),
                { absoluteUrl: (companyId: number, page: number, per_page: number) => `${baseURL}/api/v1/companies/${companyId}/emails?page=${page}&per_page=${per_page}`, requestSizeLimit: 3145728 }
            ),
            ListEmailTemplates: Object.assign(
                () => api.get<server.dto.KeyValueSublabel[]>(`/api/v1/companies/emails/templates`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/companies/emails/templates`, requestSizeLimit: 3145728 }
            ),
            FilesGet: Object.assign(
                (companyId: number, page: number, per_page: number) => api.get<server.dto.PaginationWrapper<server.dto.File[]>>(`/api/v1/companies/${companyId}/files`, { params: { page, per_page } }),
                { absoluteUrl: (companyId: number, page: number, per_page: number) => `${baseURL}/api/v1/companies/${companyId}/files?page=${page}&per_page=${per_page}`, requestSizeLimit: 3145728 }
            ),
            NameExists: Object.assign(
                (name: string, excludeCompanyId: number) => api.get<boolean>(`/api/v1/companies/nameexists`, { params: { name, excludeCompanyId } }),
                { absoluteUrl: (name: string, excludeCompanyId: number) => `${baseURL}/api/v1/companies/nameexists?name=${name}&excludeCompanyId=${excludeCompanyId}`, requestSizeLimit: 3145728 }
            ),
            UpdateMarker: Object.assign(
                (companyId: number, dto: server.dto.Geo) => api.post<any>(`/api/v1/companies/${companyId}/marker`, dto, undefined),
                { absoluteUrl: (companyId: number) => `${baseURL}/api/v1/companies/${companyId}/marker`, requestSizeLimit: 3145728 }
            ),
            CachePointOfInterestMarkers: Object.assign(
                (companyId: number, markers: server.dto.AddressMarker[]) => api.post<any>(`/api/v1/companies/${companyId}/markers`, markers, undefined),
                { absoluteUrl: (companyId: number) => `${baseURL}/api/v1/companies/${companyId}/markers`, requestSizeLimit: 3145728 }
            ),
            GetPointOfInterestMarkers: Object.assign(
                (companyId: number) => api.get<server.dto.AddressMarker[]>(`/api/v1/companies/${companyId}/markers`, undefined),
                { absoluteUrl: (companyId: number) => `${baseURL}/api/v1/companies/${companyId}/markers`, requestSizeLimit: 3145728 }
            ),
            GetNearbyAirports: Object.assign(
                (companyId: number, radius: number) => api.get<server.dto.Airport[]>(`/api/v1/companies/${companyId}/nearbyAirports`, { params: { radius } }),
                { absoluteUrl: (companyId: number, radius: number) => `${baseURL}/api/v1/companies/${companyId}/nearbyAirports?radius=${radius}`, requestSizeLimit: 3145728 }
            ),
            GetLegacyDetails: Object.assign(
                (companyId: number) => api.get<server.dto.LegacyCompany>(`/api/v1/companies/${companyId}`, undefined),
                { absoluteUrl: (companyId: number) => `${baseURL}/api/v1/companies/${companyId}`, requestSizeLimit: 3145728 }
            ),
            GetTooltip: Object.assign(
                (companyId: number) => api.get<server.dto.CompanyTooltip>(`/api/v1/companies/${companyId}/tooltip`, undefined),
                { absoluteUrl: (companyId: number) => `${baseURL}/api/v1/companies/${companyId}/tooltip`, requestSizeLimit: 3145728 }
            ),
            ListRelatedFiles: Object.assign(
                (companyId: number) => api.get<server.dto.RelatedFiles>(`/api/v1/companies/${companyId}/files/related`, undefined),
                { absoluteUrl: (companyId: number) => `${baseURL}/api/v1/companies/${companyId}/files/related`, requestSizeLimit: 3145728 }
            )
        },
        Contacts: {
            GetEmails: Object.assign(
                (contactId: number, page: number, per_page: number) => api.get<server.dto.PaginationWrapper<server.dto.EmailMessage[]>>(`/api/v1/contacts/${contactId}/emails`, { params: { page, per_page } }),
                { absoluteUrl: (contactId: number, page: number, per_page: number) => `${baseURL}/api/v1/contacts/${contactId}/emails?page=${page}&per_page=${per_page}`, requestSizeLimit: 3145728 }
            ),
            ListEmailTemplates: Object.assign(
                () => api.get<server.dto.KeyValueSublabel[]>(`/api/v1/contacts/emails/templates`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/contacts/emails/templates`, requestSizeLimit: 3145728 }
            ),
            FilesGet: Object.assign(
                (contactId: number, page: number, per_page: number) => api.get<server.dto.PaginationWrapper<server.dto.File[]>>(`/api/v1/contacts/${contactId}/files`, { params: { page, per_page } }),
                { absoluteUrl: (contactId: number, page: number, per_page: number) => `${baseURL}/api/v1/contacts/${contactId}/files?page=${page}&per_page=${per_page}`, requestSizeLimit: 3145728 }
            ),
            NameExists: Object.assign(
                (name: string) => api.get<boolean>(`/api/v1/contacts/nameexists`, { params: { name } }),
                { absoluteUrl: (name: string) => `${baseURL}/api/v1/contacts/nameexists?name=${name}`, requestSizeLimit: 3145728 }
            ),
            GetDetails: Object.assign(
                (contactId: number) => api.get<server.dto.ContactSmall>(`/api/v1/contacts/${contactId}`, undefined),
                { absoluteUrl: (contactId: number) => `${baseURL}/api/v1/contacts/${contactId}`, requestSizeLimit: 3145728 }
            ),
            RecentlyUsed: Object.assign(
                () => api.get<server.dto.ContactSmall[]>(`/api/v1/contacts/recentlyused`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/contacts/recentlyused`, requestSizeLimit: 3145728 }
            ),
            ListRelatedFiles: Object.assign(
                (contactId: number) => api.get<server.dto.RelatedFiles>(`/api/v1/contacts/${contactId}/files/related`, undefined),
                { absoluteUrl: (contactId: number) => `${baseURL}/api/v1/contacts/${contactId}/files/related`, requestSizeLimit: 3145728 }
            )
        },
        Contracts: {
            Copy: Object.assign(
                (contractId: number, data: server.dto.CopyDocumentInstruction) => api.put<server.dto.Document>(`/api/v1/contracts/${contractId}/copy`, data, undefined),
                { absoluteUrl: (contractId: number) => `${baseURL}/api/v1/contracts/${contractId}/copy`, requestSizeLimit: 3145728 }
            ),
            Update: Object.assign(
                (contractId: number, contract: server.dto.ModifyDocumentInstruction) => api.post<server.dto.Document>(`/api/v1/contracts/${contractId}`, contract, undefined),
                { absoluteUrl: (contractId: number) => `${baseURL}/api/v1/contracts/${contractId}`, requestSizeLimit: 3145728 }
            ),
            Create: Object.assign(
                (contract: server.dto.ModifyDocumentInstruction) => api.put<server.dto.Document>(`/api/v1/contracts`, contract, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/contracts`, requestSizeLimit: 3145728 }
            ),
            LinkShows: Object.assign(
                (contractId: number, data: server.dto.LinkShowInstruction[]) => api.put<any>(`/api/v1/contracts/${contractId}/shows/link`, data, undefined),
                { absoluteUrl: (contractId: number) => `${baseURL}/api/v1/contracts/${contractId}/shows/link`, requestSizeLimit: 3145728 }
            ),
            UnlinkShow: Object.assign(
                (contractId: number, showId: number) => api.put<server.dto.ShowsByParent>(`/api/v1/contracts/${contractId}/shows/${showId}/unlink`, undefined, undefined),
                { absoluteUrl: (contractId: number, showId: number) => `${baseURL}/api/v1/contracts/${contractId}/shows/${showId}/unlink`, requestSizeLimit: 3145728 }
            ),
            GetTotalsData: Object.assign(
                (bookkeepingId: number, period: server.dto.GroupByPeriodOptions) => api.get<server.dto.GroupedDocumentTotalsResult>(`/api/v1/contracts/stats/totalsdata`, { params: { bookkeepingId, period } }),
                { absoluteUrl: (bookkeepingId: number, period: server.dto.GroupByPeriodOptions) => `${baseURL}/api/v1/contracts/stats/totalsdata?bookkeepingId=${bookkeepingId}&period=${period}`, requestSizeLimit: 3145728 }
            ),
            GetTotalsPerStatus: Object.assign(
                (bookkeepingId: number, period: server.dto.PeriodFilterOptions, selectionStart: number, selectionEnd: number) => api.get<server.dto.TotalsPerStatus>(`/api/v1/contracts/stats/totalperstatus`, { params: { bookkeepingId, period, selectionStart, selectionEnd } }),
                { absoluteUrl: (bookkeepingId: number, period: server.dto.PeriodFilterOptions, selectionStart: number, selectionEnd: number) => `${baseURL}/api/v1/contracts/stats/totalperstatus?bookkeepingId=${bookkeepingId}&period=${period}&selectionStart=${selectionStart}&selectionEnd=${selectionEnd}`, requestSizeLimit: 3145728 }
            ),
            GetTotalsPerOwner: Object.assign(
                (bookkeepingId: number, period: server.dto.PeriodFilterOptions, selectionStart: number, selectionEnd: number) => api.get<server.dto.TotalsPerOwner>(`/api/v1/contracts/stats/totalperowner`, { params: { bookkeepingId, period, selectionStart, selectionEnd } }),
                { absoluteUrl: (bookkeepingId: number, period: server.dto.PeriodFilterOptions, selectionStart: number, selectionEnd: number) => `${baseURL}/api/v1/contracts/stats/totalperowner?bookkeepingId=${bookkeepingId}&period=${period}&selectionStart=${selectionStart}&selectionEnd=${selectionEnd}`, requestSizeLimit: 3145728 }
            ),
            GetEmails: Object.assign(
                (contractId: number, page: number, per_page: number) => api.get<server.dto.PaginationWrapper<server.dto.EmailMessage[]>>(`/api/v1/contracts/${contractId}/emails`, { params: { page, per_page } }),
                { absoluteUrl: (contractId: number, page: number, per_page: number) => `${baseURL}/api/v1/contracts/${contractId}/emails?page=${page}&per_page=${per_page}`, requestSizeLimit: 3145728 }
            ),
            ListEmailTemplates: Object.assign(
                () => api.get<server.dto.KeyValueSublabel[]>(`/api/v1/contracts/emails/templates`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/contracts/emails/templates`, requestSizeLimit: 3145728 }
            ),
            FilesGet: Object.assign(
                (contractId: number, page: number, per_page: number) => api.get<server.dto.PaginationWrapper<server.dto.File[]>>(`/api/v1/contracts/${contractId}/files`, { params: { page, per_page } }),
                { absoluteUrl: (contractId: number, page: number, per_page: number) => `${baseURL}/api/v1/contracts/${contractId}/files?page=${page}&per_page=${per_page}`, requestSizeLimit: 3145728 }
            ),
            ProductsGet: Object.assign(
                (contractId: number) => api.get<server.dto.ProductsByParent>(`/api/v1/contracts/${contractId}/products`, undefined),
                { absoluteUrl: (contractId: number) => `${baseURL}/api/v1/contracts/${contractId}/products`, requestSizeLimit: 3145728 }
            ),
            ProductsUpdateSort: Object.assign(
                (contractId: number, ids: number[]) => api.post<void>(`/api/v1/contracts/${contractId}/products/sort`, undefined, { params: { ids } }),
                { absoluteUrl: (contractId: number, ids: number[]) => `${baseURL}/api/v1/contracts/${contractId}/products/sort?ids=${ids}`, requestSizeLimit: 3145728 }
            ),
            ProductCreate: Object.assign(
                (contractId: number, data: server.dto.ProductPost) => api.put<server.dto.ApiResponseWrapper<server.dto.ProductsByParent>>(`/api/v1/contracts/${contractId}/products`, data, undefined),
                { absoluteUrl: (contractId: number) => `${baseURL}/api/v1/contracts/${contractId}/products`, requestSizeLimit: 3145728 }
            ),
            ProductUpdate: Object.assign(
                (contractId: number, productId: number, data: server.dto.ProductPost) => api.post<server.dto.ApiResponseWrapper<server.dto.ProductsByParent>>(`/api/v1/contracts/${contractId}/products/${productId}`, data, undefined),
                { absoluteUrl: (contractId: number, productId: number) => `${baseURL}/api/v1/contracts/${contractId}/products/${productId}`, requestSizeLimit: 3145728 }
            ),
            ProductDelete: Object.assign(
                (contractId: number, productId: number) => api.delete(`/api/v1/contracts/${contractId}/products/${productId}`, undefined),
                { absoluteUrl: (contractId: number, productId: number) => `${baseURL}/api/v1/contracts/${contractId}/products/${productId}`, requestSizeLimit: 3145728 }
            ),
            ListRelatedFiles: Object.assign(
                (contractId: number) => api.get<server.dto.RelatedFiles>(`/api/v1/contracts/${contractId}/files/related`, undefined),
                { absoluteUrl: (contractId: number) => `${baseURL}/api/v1/contracts/${contractId}/files/related`, requestSizeLimit: 3145728 }
            )
        },
        Diagnostic: {
            SetCache: Object.assign(
                (value: string) => api.get<string>(`/api/v1/diagnostic/cache/set`, { params: { value } }),
                { absoluteUrl: (value: string) => `${baseURL}/api/v1/diagnostic/cache/set?value=${value}`, requestSizeLimit: 3145728 }
            ),
            GetCache: Object.assign(
                () => api.get<string>(`/api/v1/diagnostic/cache/get`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/diagnostic/cache/get`, requestSizeLimit: 3145728 }
            ),
            LogSentryWarning: Object.assign(
                () => api.get<void>(`/api/v1/diagnostic/SentryWarning`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/diagnostic/SentryWarning`, requestSizeLimit: 3145728 }
            )
        },
        Document: {
            ListShows: Object.assign(
                (documentId: number) => api.get<server.dto.ShowsByParent>(`/api/v1/document/${documentId}/shows`, undefined),
                { absoluteUrl: (documentId: number) => `${baseURL}/api/v1/document/${documentId}/shows`, requestSizeLimit: 3145728 }
            )
        },
        EmailTemplates: {
            Copy: Object.assign(
                (emailTemplateId: number) => api.post<number>(`/api/v1/emailtemplates/${emailTemplateId}/copy`, undefined, undefined),
                { absoluteUrl: (emailTemplateId: number) => `${baseURL}/api/v1/emailtemplates/${emailTemplateId}/copy`, requestSizeLimit: 3145728 }
            ),
            Delete: Object.assign(
                (emailTemplateId: number) => api.delete(`/api/v1/emailtemplates/${emailTemplateId}`, undefined),
                { absoluteUrl: (emailTemplateId: number) => `${baseURL}/api/v1/emailtemplates/${emailTemplateId}`, requestSizeLimit: 3145728 }
            )
        },
        ExternalCalendars: {
            Delete: Object.assign(
                (externalCalendarId: number) => api.delete(`/api/v1/externalcalendars/${externalCalendarId}`, undefined),
                { absoluteUrl: (externalCalendarId: number) => `${baseURL}/api/v1/externalcalendars/${externalCalendarId}`, requestSizeLimit: 3145728 }
            ),
            GetExternalEvent: Object.assign(
                (externalCalendarId: number, externalEventId: number) => api.get<any>(`/api/v1/externalcalendars/${externalCalendarId}/events/${externalEventId}`, undefined),
                { absoluteUrl: (externalCalendarId: number, externalEventId: number) => `${baseURL}/api/v1/externalcalendars/${externalCalendarId}/events/${externalEventId}`, requestSizeLimit: 3145728 }
            ),
            GetToolTip: Object.assign(
                (externalEventId: number) => api.get<server.dto.Tooltip>(`/api/v1/externalcalendars/${externalEventId}/tooltip`, undefined),
                { absoluteUrl: (externalEventId: number) => `${baseURL}/api/v1/externalcalendars/${externalEventId}/tooltip`, requestSizeLimit: 3145728 }
            ),
            ListMyGoogleCalendars: Object.assign(
                () => api.get<server.dto.GoogleCalendar[]>(`/api/v1/externalcalendars/googlecalendars/list`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/externalcalendars/googlecalendars/list`, requestSizeLimit: 3145728 }
            )
        },
        ExternalEventsources: {
            GetExternalEvent: Object.assign(
                (externalCalendarId: number, externalEventId: number) => api.get<any>(`/api/v1/externaleventsources/${externalCalendarId}/events/${externalEventId}`, undefined),
                { absoluteUrl: (externalCalendarId: number, externalEventId: number) => `${baseURL}/api/v1/externaleventsources/${externalCalendarId}/events/${externalEventId}`, requestSizeLimit: 3145728 }
            )
        },
        Files: {
            DownloadClientFile: Object.assign(
                (fileId: string, fileName: string) => api.get<any>(`/api/v1/files/clientfile/${fileId}/download/${fileName}`, undefined),
                { absoluteUrl: (fileId: string, fileName: string) => `${baseURL}/api/v1/files/clientfile/${fileId}/download/${fileName}`, requestSizeLimit: 3145728 }
            ),
            DownloadFlightFile: Object.assign(
                (fileId: string, fileName: string) => api.get<any>(`/api/v1/files/flight/${fileId}/download/${fileName}`, undefined),
                { absoluteUrl: (fileId: string, fileName: string) => `${baseURL}/api/v1/files/flight/${fileId}/download/${fileName}`, requestSizeLimit: 3145728 }
            ),
            DownloadTrainFile: Object.assign(
                (fileId: string, fileName: string) => api.get<any>(`/api/v1/files/train/${fileId}/download/${fileName}`, undefined),
                { absoluteUrl: (fileId: string, fileName: string) => `${baseURL}/api/v1/files/train/${fileId}/download/${fileName}`, requestSizeLimit: 3145728 }
            )
        },
        Flights: {
            GetFlight: Object.assign(
                (flightId: number) => api.get<server.dto.Flight>(`/api/v1/flights/${flightId}`, undefined),
                { absoluteUrl: (flightId: number) => `${baseURL}/api/v1/flights/${flightId}`, requestSizeLimit: 3145728 }
            ),
            GetFlightBySegment: Object.assign(
                (segmentId: number) => api.get<server.dto.Flight>(`/api/v1/flights/bysegment/${segmentId}`, undefined),
                { absoluteUrl: (segmentId: number) => `${baseURL}/api/v1/flights/bysegment/${segmentId}`, requestSizeLimit: 3145728 }
            ),
            ListFlightFiles: Object.assign(
                (flightId: number) => api.get<server.dto.File[]>(`/api/v1/flights/${flightId}/files`, undefined),
                { absoluteUrl: (flightId: number) => `${baseURL}/api/v1/flights/${flightId}/files`, requestSizeLimit: 3145728 }
            ),
            GetFrequentFlyerPrograms: Object.assign(
                () => api.get<server.dto.FrequentFlyerProgram[]>(`/api/v1/flights/frequentflyerprograms`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/flights/frequentflyerprograms`, requestSizeLimit: 3145728 }
            ),
            CreateFlight: Object.assign(
                (flight: server.dto.Flight) => api.post<server.dto.Flight>(`/api/v1/flights`, flight, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/flights`, requestSizeLimit: 3145728 }
            ),
            CopyFlight: Object.assign(
                (flightId: number) => api.put<number>(`/api/v1/flights/${flightId}/copy`, undefined, undefined),
                { absoluteUrl: (flightId: number) => `${baseURL}/api/v1/flights/${flightId}/copy`, requestSizeLimit: 3145728 }
            ),
            UpdateFlight: Object.assign(
                (flightId: number, flight: server.dto.Flight) => api.put<server.dto.Flight>(`/api/v1/flights/${flightId}`, flight, undefined),
                { absoluteUrl: (flightId: number) => `${baseURL}/api/v1/flights/${flightId}`, requestSizeLimit: 3145728 }
            ),
            Delete: Object.assign(
                (flightId: number) => api.delete(`/api/v1/flights/${flightId}`, undefined),
                { absoluteUrl: (flightId: number) => `${baseURL}/api/v1/flights/${flightId}`, requestSizeLimit: 3145728 }
            ),
            DeleteSegment: Object.assign(
                (flightSegmentId: number) => api.post<server.dto.Flight>(`/api/v1/flights/segments/${flightSegmentId}/delete`, undefined, undefined),
                { absoluteUrl: (flightSegmentId: number) => `${baseURL}/api/v1/flights/segments/${flightSegmentId}/delete`, requestSizeLimit: 3145728 }
            ),
            GetLegacyFlightJourney: Object.assign(
                (flightId: number, flightSegmentId: number) => api.get<any>(`/api/v1/flights/${flightId}/segments/${flightSegmentId}`, undefined),
                { absoluteUrl: (flightId: number, flightSegmentId: number) => `${baseURL}/api/v1/flights/${flightId}/segments/${flightSegmentId}`, requestSizeLimit: 3145728 }
            ),
            RetrieveFlightDetails: Object.assign(
                (flightNumber: number, airlineId: number, departure: Date) => api.get<server.dto.FlightSegment[]>(`/api/v1/flights/flightdetails`, { params: { flightNumber, airlineId, departure } }),
                { absoluteUrl: (flightNumber: number, airlineId: number, departure: Date) => `${baseURL}/api/v1/flights/flightdetails?flightNumber=${flightNumber}&airlineId=${airlineId}&departure=${departure}`, requestSizeLimit: 3145728 }
            ),
            GetToolTip: Object.assign(
                (flightSegmentId: number) => api.get<server.dto.Tooltip>(`/api/v1/flights/segments/${flightSegmentId}/tooltip`, undefined),
                { absoluteUrl: (flightSegmentId: number) => `${baseURL}/api/v1/flights/segments/${flightSegmentId}/tooltip`, requestSizeLimit: 3145728 }
            )
        },
        FreeFieldCategories: {
            Delete: Object.assign(
                (freeFieldCategoryId: number, targetId: number) => api.delete(`/api/v1/freefieldcategories/${freeFieldCategoryId}`, { params: { targetId } }),
                { absoluteUrl: (freeFieldCategoryId: number, targetId: number) => `${baseURL}/api/v1/freefieldcategories/${freeFieldCategoryId}?targetId=${targetId}`, requestSizeLimit: 3145728 }
            ),
            StoreSorted: Object.assign(
                (mainArea: server.dto.MainAreas, keys: number[]) => api.post<any>(`/api/v1/freefieldcategories/sort`, undefined, { params: { mainArea, keys } }),
                { absoluteUrl: (mainArea: server.dto.MainAreas, keys: number[]) => `${baseURL}/api/v1/freefieldcategories/sort?mainArea=${mainArea}&keys=${keys}`, requestSizeLimit: 3145728 }
            ),
            StoreSortedDefinitions: Object.assign(
                (freeFieldCategoryId: number, keys: number[]) => api.post<any>(`/api/v1/freefieldcategories/${freeFieldCategoryId}/definitions/sort`, undefined, { params: { keys } }),
                { absoluteUrl: (freeFieldCategoryId: number, keys: number[]) => `${baseURL}/api/v1/freefieldcategories/${freeFieldCategoryId}/definitions/sort?keys=${keys}`, requestSizeLimit: 3145728 }
            )
        },
        FreeFieldDefinitions: {
            Delete: Object.assign(
                (freefieldDefinitionId: number) => api.delete(`/api/v1/freefielddefinitions/${freefieldDefinitionId}`, undefined),
                { absoluteUrl: (freefieldDefinitionId: number) => `${baseURL}/api/v1/freefielddefinitions/${freefieldDefinitionId}`, requestSizeLimit: 3145728 }
            ),
            ReferenceExists: Object.assign(
                (mainArea: server.dto.MainAreas, reference: string, excludeId: number) => api.get<boolean>(`/api/v1/freefielddefinitions/referenceexists`, { params: { mainArea, reference, excludeId } }),
                { absoluteUrl: (mainArea: server.dto.MainAreas, reference: string, excludeId: number) => `${baseURL}/api/v1/freefielddefinitions/referenceexists?mainArea=${mainArea}&reference=${reference}&excludeId=${excludeId}`, requestSizeLimit: 3145728 }
            ),
            AssignProfileLevelsToFreeFieldDefinition: Object.assign(
                (freeFieldDefinitionId: number, instruction: server.dto.SetFreeFieldProfilesForFreeFieldInstruction) => api.post<server.dto.FreeFieldProfileWithPermission[]>(`/api/v1/freefielddefinitions/${freeFieldDefinitionId}/profiles/assignlevels`, instruction, undefined),
                { absoluteUrl: (freeFieldDefinitionId: number) => `${baseURL}/api/v1/freefielddefinitions/${freeFieldDefinitionId}/profiles/assignlevels`, requestSizeLimit: 3145728 }
            ),
            ListProfilesByDefinition: Object.assign(
                (freeFieldDefinitionId: number) => api.get<server.dto.FreeFieldProfileWithPermission[]>(`/api/v1/freefielddefinitions/${freeFieldDefinitionId}/profiles`, undefined),
                { absoluteUrl: (freeFieldDefinitionId: number) => `${baseURL}/api/v1/freefielddefinitions/${freeFieldDefinitionId}/profiles`, requestSizeLimit: 3145728 }
            )
        },
        GermanTaxOffice: {
            Validate: Object.assign(
                (req: server.dto.GermanTaxOfficeRequest) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.GermanTaxOfficeConfirmation, server.dto.GermanTaxOfficeRequest>>(`/api/v1/germantaxoffice/validate`, req, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/germantaxoffice/validate`, requestSizeLimit: 3145728 }
            )
        },
        GooglePlaces: {
            TextSearch: Object.assign(
                (query: string, type: any) => api.get<server.dto.GooglePlaceAutocomplete[]>(`/api/v1/googleplaces/textsearch`, { params: { query, type } }),
                { absoluteUrl: (query: string, type: any) => `${baseURL}/api/v1/googleplaces/textsearch?query=${query}&type=${type}`, requestSizeLimit: 3145728 }
            ),
            Autocomplete: Object.assign(
                (query: string) => api.get<server.dto.GooglePlaceAutocomplete[]>(`/api/v1/googleplaces/autocomplete`, { params: { query } }),
                { absoluteUrl: (query: string) => `${baseURL}/api/v1/googleplaces/autocomplete?query=${query}`, requestSizeLimit: 3145728 }
            ),
            GetPlace: Object.assign(
                (placeId: string, calledFrom: server.dto.GooglePlaceCalledFrom) => api.get<server.dto.GooglePlace>(`/api/v1/googleplaces/places/${placeId}`, { params: { calledFrom } }),
                { absoluteUrl: (placeId: string, calledFrom: server.dto.GooglePlaceCalledFrom) => `${baseURL}/api/v1/googleplaces/places/${placeId}?calledFrom=${calledFrom}`, requestSizeLimit: 3145728 }
            )
        },
        GroundTransports: {
            GetLegacyGroundTransport: Object.assign(
                (groundTransportId: number) => api.get<any>(`/api/v1/groundtransports/${groundTransportId}`, undefined),
                { absoluteUrl: (groundTransportId: number) => `${baseURL}/api/v1/groundtransports/${groundTransportId}`, requestSizeLimit: 3145728 }
            ),
            CreateGroundTransport: Object.assign(
                (groundTransport: server.dto.GroundTransport) => api.post<server.dto.GroundTransport>(`/api/v1/groundtransports`, groundTransport, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/groundtransports`, requestSizeLimit: 3145728 }
            ),
            UpdateGroundTransport: Object.assign(
                (groundTransportId: number, groundTransport: server.dto.GroundTransport) => api.put<server.dto.GroundTransport>(`/api/v1/groundtransports/${groundTransportId}`, groundTransport, undefined),
                { absoluteUrl: (groundTransportId: number) => `${baseURL}/api/v1/groundtransports/${groundTransportId}`, requestSizeLimit: 3145728 }
            ),
            CopyGroundTransport: Object.assign(
                (groundTransportId: number) => api.put<number>(`/api/v1/groundtransports/${groundTransportId}/copy`, undefined, undefined),
                { absoluteUrl: (groundTransportId: number) => `${baseURL}/api/v1/groundtransports/${groundTransportId}/copy`, requestSizeLimit: 3145728 }
            ),
            Delete: Object.assign(
                (groundTransportId: number) => api.delete(`/api/v1/groundtransports/${groundTransportId}`, undefined),
                { absoluteUrl: (groundTransportId: number) => `${baseURL}/api/v1/groundtransports/${groundTransportId}`, requestSizeLimit: 3145728 }
            ),
            GetToolTip: Object.assign(
                (groundTransportId: number) => api.get<server.dto.Tooltip>(`/api/v1/groundtransports/${groundTransportId}/tooltip`, undefined),
                { absoluteUrl: (groundTransportId: number) => `${baseURL}/api/v1/groundtransports/${groundTransportId}/tooltip`, requestSizeLimit: 3145728 }
            ),
            GoogleDirections: Object.assign(
                (startPlaceId: string, endPlaceId: string) => api.get<server.dto.GoogleDirections>(`/api/v1/groundtransports/googledirections`, { params: { startPlaceId, endPlaceId } }),
                { absoluteUrl: (startPlaceId: string, endPlaceId: string) => `${baseURL}/api/v1/groundtransports/googledirections?startPlaceId=${startPlaceId}&endPlaceId=${endPlaceId}`, requestSizeLimit: 3145728 }
            )
        },
        Guestlist: {
            GetGuestlistByShow: Object.assign(
                (showId: number) => api.get<server.dto.ApiResponseWrapperWithRequest<server.dto.Guestlist, Object>>(`/api/v1/guestlist/${showId}`, undefined),
                { absoluteUrl: (showId: number) => `${baseURL}/api/v1/guestlist/${showId}`, requestSizeLimit: 3145728 }
            ),
            UpdateGuestlist: Object.assign(
                (dto: server.dto.Guestlist) => api.post<server.dto.ApiResponseWrapperWithRequest<string, Object>>(`/api/v1/guestlist`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/guestlist`, requestSizeLimit: 3145728 }
            ),
            AddGuestlist: Object.assign(
                (showId: number) => api.put<server.dto.ApiResponseWrapperWithRequest<string, Object>>(`/api/v1/guestlist/${showId}`, undefined, undefined),
                { absoluteUrl: (showId: number) => `${baseURL}/api/v1/guestlist/${showId}`, requestSizeLimit: 3145728 }
            ),
            DeleteGuestlist: Object.assign(
                (guestlistId: number) => api.delete(`/api/v1/guestlist/${guestlistId}`, undefined),
                { absoluteUrl: (guestlistId: number) => `${baseURL}/api/v1/guestlist/${guestlistId}`, requestSizeLimit: 3145728 }
            ),
            AddGuestlistPlace: Object.assign(
                (dto: server.dto.GuestlistPlace, guestlistId: number) => api.put<server.dto.ApiResponseWrapperWithRequest<string, Object>>(`/api/v1/guestlist/place`, dto, { params: { guestlistId } }),
                { absoluteUrl: (guestlistId: number) => `${baseURL}/api/v1/guestlist/place?guestlistId=${guestlistId}`, requestSizeLimit: 3145728 }
            ),
            LegacyRemoveGuestlistPlace: Object.assign(
                (placeId: number) => api.delete(`/api/v1/guestlist/place`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/guestlist/place`, requestSizeLimit: 3145728 }
            ),
            RemoveGuestlistPlace: Object.assign(
                (placeId: number) => api.delete(`/api/v1/guestlist/place/${placeId}`, undefined),
                { absoluteUrl: (placeId: number) => `${baseURL}/api/v1/guestlist/place/${placeId}`, requestSizeLimit: 3145728 }
            ),
            UpdateGuestlistPlace: Object.assign(
                (dto: server.dto.GuestlistPlace) => api.post<any>(`/api/v1/guestlist/place`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/guestlist/place`, requestSizeLimit: 3145728 }
            ),
            AddGuest: Object.assign(
                (dto: server.dto.Guest, placeId: number) => api.put<server.dto.ApiResponseWrapperWithRequest<string, Object>>(`/api/v1/guestlist/place/${placeId}/guest`, dto, undefined),
                { absoluteUrl: (placeId: number) => `${baseURL}/api/v1/guestlist/place/${placeId}/guest`, requestSizeLimit: 3145728 }
            ),
            LegacyRemoveGuest: Object.assign(
                (guestId: number) => api.delete(`/api/v1/guestlist/place/guest`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/guestlist/place/guest`, requestSizeLimit: 3145728 }
            ),
            RemoveGuest: Object.assign(
                (guestId: number) => api.delete(`/api/v1/guestlist/place/guest/${guestId}`, undefined),
                { absoluteUrl: (guestId: number) => `${baseURL}/api/v1/guestlist/place/guest/${guestId}`, requestSizeLimit: 3145728 }
            ),
            UpdateGuest: Object.assign(
                (dto: server.dto.Guest) => api.post<server.dto.ApiResponseWrapperWithRequest<string, Object>>(`/api/v1/guestlist/place/guest`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/guestlist/place/guest`, requestSizeLimit: 3145728 }
            )
        },
        Invoices: {
            GetEmails: Object.assign(
                (invoiceId: number, page: number, per_page: number) => api.get<server.dto.PaginationWrapper<server.dto.EmailMessage[]>>(`/api/v1/invoices/${invoiceId}/emails`, { params: { page, per_page } }),
                { absoluteUrl: (invoiceId: number, page: number, per_page: number) => `${baseURL}/api/v1/invoices/${invoiceId}/emails?page=${page}&per_page=${per_page}`, requestSizeLimit: 3145728 }
            ),
            ListEmailTemplates: Object.assign(
                () => api.get<server.dto.KeyValueSublabel[]>(`/api/v1/invoices/emails/templates`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/invoices/emails/templates`, requestSizeLimit: 3145728 }
            ),
            FilesGet: Object.assign(
                (invoiceId: number, page: number, per_page: number) => api.get<server.dto.PaginationWrapper<server.dto.File[]>>(`/api/v1/invoices/${invoiceId}/files`, { params: { page, per_page } }),
                { absoluteUrl: (invoiceId: number, page: number, per_page: number) => `${baseURL}/api/v1/invoices/${invoiceId}/files?page=${page}&per_page=${per_page}`, requestSizeLimit: 3145728 }
            ),
            ProductsGet: Object.assign(
                (invoiceId: number) => api.get<server.dto.ProductsByParent>(`/api/v1/invoices/${invoiceId}/products`, undefined),
                { absoluteUrl: (invoiceId: number) => `${baseURL}/api/v1/invoices/${invoiceId}/products`, requestSizeLimit: 3145728 }
            ),
            ProductsUpdateSort: Object.assign(
                (invoiceId: number, ids: number[]) => api.post<void>(`/api/v1/invoices/${invoiceId}/products/sort`, undefined, { params: { ids } }),
                { absoluteUrl: (invoiceId: number, ids: number[]) => `${baseURL}/api/v1/invoices/${invoiceId}/products/sort?ids=${ids}`, requestSizeLimit: 3145728 }
            ),
            ProductCreate: Object.assign(
                (invoiceId: number, data: server.dto.ProductPost) => api.put<server.dto.ApiResponseWrapper<server.dto.ProductsByParent>>(`/api/v1/invoices/${invoiceId}/products`, data, undefined),
                { absoluteUrl: (invoiceId: number) => `${baseURL}/api/v1/invoices/${invoiceId}/products`, requestSizeLimit: 3145728 }
            ),
            ProductUpdate: Object.assign(
                (invoiceId: number, productId: number, data: server.dto.ProductPost) => api.post<server.dto.ApiResponseWrapper<server.dto.ProductsByParent>>(`/api/v1/invoices/${invoiceId}/products/${productId}`, data, undefined),
                { absoluteUrl: (invoiceId: number, productId: number) => `${baseURL}/api/v1/invoices/${invoiceId}/products/${productId}`, requestSizeLimit: 3145728 }
            ),
            ProductDelete: Object.assign(
                (invoiceId: number, productId: number) => api.delete(`/api/v1/invoices/${invoiceId}/products/${productId}`, undefined),
                { absoluteUrl: (invoiceId: number, productId: number) => `${baseURL}/api/v1/invoices/${invoiceId}/products/${productId}`, requestSizeLimit: 3145728 }
            ),
            Copy: Object.assign(
                (sourceDocumentId: number, data: server.dto.CopyInvoiceInstruction) => api.put<server.dto.Invoice>(`/api/v1/invoices/${sourceDocumentId}/copy`, data, undefined),
                { absoluteUrl: (sourceDocumentId: number) => `${baseURL}/api/v1/invoices/${sourceDocumentId}/copy`, requestSizeLimit: 3145728 }
            ),
            Create: Object.assign(
                (dto: server.dto.ModifyInvoiceInstruction) => api.put<server.dto.Invoice>(`/api/v1/invoices`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/invoices`, requestSizeLimit: 3145728 }
            ),
            Update: Object.assign(
                (invoiceId: number, dto: server.dto.ModifyInvoiceInstruction) => api.post<server.dto.Invoice>(`/api/v1/invoices/${invoiceId}/update`, dto, undefined),
                { absoluteUrl: (invoiceId: number) => `${baseURL}/api/v1/invoices/${invoiceId}/update`, requestSizeLimit: 3145728 }
            ),
            MakeCredit: Object.assign(
                (invoiceId: number, makeCredit: server.dto.MakeCreditInstruction) => api.post<any>(`/api/v1/invoices/${invoiceId}/makecredit`, makeCredit, undefined),
                { absoluteUrl: (invoiceId: number) => `${baseURL}/api/v1/invoices/${invoiceId}/makecredit`, requestSizeLimit: 3145728 }
            ),
            ListPayments: Object.assign(
                (invoiceId: number) => api.get<server.dto.PaymentsForInvoice>(`/api/v1/invoices/${invoiceId}/payments`, undefined),
                { absoluteUrl: (invoiceId: number) => `${baseURL}/api/v1/invoices/${invoiceId}/payments`, requestSizeLimit: 3145728 }
            ),
            LockPerMonth: Object.assign(
                (bookkeepingId: number, month: number, year: number) => api.post<any>(`/api/v1/invoices/lockpermonth`, undefined, { params: { bookkeepingId, month, year } }),
                { absoluteUrl: (bookkeepingId: number, month: number, year: number) => `${baseURL}/api/v1/invoices/lockpermonth?bookkeepingId=${bookkeepingId}&month=${month}&year=${year}`, requestSizeLimit: 3145728 }
            ),
            LinkShows: Object.assign(
                (invoiceId: number, data: server.dto.LinkShowInstruction[]) => api.put<any>(`/api/v1/invoices/${invoiceId}/shows/link`, data, undefined),
                { absoluteUrl: (invoiceId: number) => `${baseURL}/api/v1/invoices/${invoiceId}/shows/link`, requestSizeLimit: 3145728 }
            ),
            UnlinkShow: Object.assign(
                (invoiceId: number, showId: number) => api.put<server.dto.ShowsByParent>(`/api/v1/invoices/${invoiceId}/shows/${showId}/unlink`, undefined, undefined),
                { absoluteUrl: (invoiceId: number, showId: number) => `${baseURL}/api/v1/invoices/${invoiceId}/shows/${showId}/unlink`, requestSizeLimit: 3145728 }
            ),
            GetTotalsData: Object.assign(
                (bookkeepingId: number, period: server.dto.GroupByPeriodOptions) => api.get<server.dto.GroupedDocumentTotalsResult>(`/api/v1/invoices/stats/totalsdata`, { params: { bookkeepingId, period } }),
                { absoluteUrl: (bookkeepingId: number, period: server.dto.GroupByPeriodOptions) => `${baseURL}/api/v1/invoices/stats/totalsdata?bookkeepingId=${bookkeepingId}&period=${period}`, requestSizeLimit: 3145728 }
            ),
            GetTotalsPerStatus: Object.assign(
                (bookkeepingId: number, period: server.dto.PeriodFilterOptions, selectionStart: number, selectionEnd: number) => api.get<server.dto.TotalsPerStatus>(`/api/v1/invoices/stats/totalperstatus`, { params: { bookkeepingId, period, selectionStart, selectionEnd } }),
                { absoluteUrl: (bookkeepingId: number, period: server.dto.PeriodFilterOptions, selectionStart: number, selectionEnd: number) => `${baseURL}/api/v1/invoices/stats/totalperstatus?bookkeepingId=${bookkeepingId}&period=${period}&selectionStart=${selectionStart}&selectionEnd=${selectionEnd}`, requestSizeLimit: 3145728 }
            ),
            GetTotalsPerOwner: Object.assign(
                (bookkeepingId: number, period: server.dto.PeriodFilterOptions, selectionStart: number, selectionEnd: number) => api.get<server.dto.TotalsPerOwner>(`/api/v1/invoices/stats/totalperowner`, { params: { bookkeepingId, period, selectionStart, selectionEnd } }),
                { absoluteUrl: (bookkeepingId: number, period: server.dto.PeriodFilterOptions, selectionStart: number, selectionEnd: number) => `${baseURL}/api/v1/invoices/stats/totalperowner?bookkeepingId=${bookkeepingId}&period=${period}&selectionStart=${selectionStart}&selectionEnd=${selectionEnd}`, requestSizeLimit: 3145728 }
            ),
            ListRelatedFiles: Object.assign(
                (invoiceId: number) => api.get<server.dto.RelatedFiles>(`/api/v1/invoices/${invoiceId}/files/related`, undefined),
                { absoluteUrl: (invoiceId: number) => `${baseURL}/api/v1/invoices/${invoiceId}/files/related`, requestSizeLimit: 3145728 }
            )
        },
        NorwegianCompanyRegister: {
            TextSearch: Object.assign(
                (query: string, size: number) => api.get<server.dto.NorwegianCompanyRegistration[]>(`/api/v1/norwegiancompanyregister/textsearch`, { params: { query, size } }),
                { absoluteUrl: (query: string, size: number) => `${baseURL}/api/v1/norwegiancompanyregister/textsearch?query=${query}&size=${size}`, requestSizeLimit: 3145728 }
            )
        },
        Notifications: {
            GetNotifications: Object.assign(
                (forMobile: boolean, page: number, per_page: number) => api.get<server.dto.PaginationWrapper<server.dto.Notification[]>>(`/api/v1/notifications`, { params: { forMobile, page, per_page } }),
                { absoluteUrl: (forMobile: boolean, page: number, per_page: number) => `${baseURL}/api/v1/notifications?forMobile=${forMobile}&page=${page}&per_page=${per_page}`, requestSizeLimit: 3145728 }
            ),
            MarkRead: Object.assign(
                (notifications: number[], read: boolean) => api.post<any>(`/api/v1/notifications/read/${read}`, notifications, undefined),
                { absoluteUrl: (read: boolean) => `${baseURL}/api/v1/notifications/read/${read}`, requestSizeLimit: 3145728 }
            ),
            MarkAllSeen: Object.assign(
                (forMobile: boolean) => api.post<any>(`/api/v1/notifications/allseen`, undefined, { params: { forMobile } }),
                { absoluteUrl: (forMobile: boolean) => `${baseURL}/api/v1/notifications/allseen?forMobile=${forMobile}`, requestSizeLimit: 3145728 }
            )
        },
        ProductGroups: {
            Delete: Object.assign(
                (productGroupId: number) => api.delete(`/api/v1/productgroups/${productGroupId}`, undefined),
                { absoluteUrl: (productGroupId: number) => `${baseURL}/api/v1/productgroups/${productGroupId}`, requestSizeLimit: 3145728 }
            )
        },
        Productions: {
            Copy: Object.assign(
                (productionId: number) => api.post<number>(`/api/v1/productions/${productionId}/copy`, undefined, undefined),
                { absoluteUrl: (productionId: number) => `${baseURL}/api/v1/productions/${productionId}/copy`, requestSizeLimit: 3145728 }
            )
        },
        ProductTemplates: {
            Copy: Object.assign(
                (productTemplateId: number) => api.post<number>(`/api/v1/producttemplates/${productTemplateId}/copy`, undefined, undefined),
                { absoluteUrl: (productTemplateId: number) => `${baseURL}/api/v1/producttemplates/${productTemplateId}/copy`, requestSizeLimit: 3145728 }
            ),
            Delete: Object.assign(
                (productTemplateId: number) => api.delete(`/api/v1/producttemplates/${productTemplateId}`, undefined),
                { absoluteUrl: (productTemplateId: number) => `${baseURL}/api/v1/producttemplates/${productTemplateId}`, requestSizeLimit: 3145728 }
            )
        },
        Promoters: {
            GetDetails: Object.assign(
                (promoterId: number) => api.get<server.dto.LegacyPromoter>(`/api/v1/promoters/${promoterId}`, undefined),
                { absoluteUrl: (promoterId: number) => `${baseURL}/api/v1/promoters/${promoterId}`, requestSizeLimit: 3145728 }
            )
        },
        PurchaseAgreements: {
            Copy: Object.assign(
                (purchaseAgreementId: number, data: server.dto.CopyDocumentInstruction) => api.put<server.dto.Document>(`/api/v1/purchaseagreements/${purchaseAgreementId}/copy`, data, undefined),
                { absoluteUrl: (purchaseAgreementId: number) => `${baseURL}/api/v1/purchaseagreements/${purchaseAgreementId}/copy`, requestSizeLimit: 3145728 }
            ),
            Update: Object.assign(
                (purchaseAgreementId: number, purchaseAgreement: server.dto.ModifyDocumentInstruction) => api.post<server.dto.Document>(`/api/v1/purchaseagreements/${purchaseAgreementId}`, purchaseAgreement, undefined),
                { absoluteUrl: (purchaseAgreementId: number) => `${baseURL}/api/v1/purchaseagreements/${purchaseAgreementId}`, requestSizeLimit: 3145728 }
            ),
            Create: Object.assign(
                (purchaseAgreement: server.dto.ModifyDocumentInstruction) => api.put<server.dto.Document>(`/api/v1/purchaseagreements`, purchaseAgreement, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/purchaseagreements`, requestSizeLimit: 3145728 }
            ),
            LinkShows: Object.assign(
                (purchaseAgreementId: number, data: server.dto.LinkShowInstruction[]) => api.put<any>(`/api/v1/purchaseagreements/${purchaseAgreementId}/shows/link`, data, undefined),
                { absoluteUrl: (purchaseAgreementId: number) => `${baseURL}/api/v1/purchaseagreements/${purchaseAgreementId}/shows/link`, requestSizeLimit: 3145728 }
            ),
            UnlinkShow: Object.assign(
                (purchaseAgreementId: number, showId: number) => api.put<server.dto.ShowsByParent>(`/api/v1/purchaseagreements/${purchaseAgreementId}/shows/${showId}/unlink`, undefined, undefined),
                { absoluteUrl: (purchaseAgreementId: number, showId: number) => `${baseURL}/api/v1/purchaseagreements/${purchaseAgreementId}/shows/${showId}/unlink`, requestSizeLimit: 3145728 }
            ),
            GetTotalsData: Object.assign(
                (bookkeepingId: number, period: server.dto.GroupByPeriodOptions) => api.get<server.dto.GroupedDocumentTotalsResult>(`/api/v1/purchaseagreements/stats/totalsdata`, { params: { bookkeepingId, period } }),
                { absoluteUrl: (bookkeepingId: number, period: server.dto.GroupByPeriodOptions) => `${baseURL}/api/v1/purchaseagreements/stats/totalsdata?bookkeepingId=${bookkeepingId}&period=${period}`, requestSizeLimit: 3145728 }
            ),
            GetTotalsPerStatus: Object.assign(
                (bookkeepingId: number, period: server.dto.PeriodFilterOptions, selectionStart: number, selectionEnd: number) => api.get<server.dto.TotalsPerStatus>(`/api/v1/purchaseagreements/stats/totalperstatus`, { params: { bookkeepingId, period, selectionStart, selectionEnd } }),
                { absoluteUrl: (bookkeepingId: number, period: server.dto.PeriodFilterOptions, selectionStart: number, selectionEnd: number) => `${baseURL}/api/v1/purchaseagreements/stats/totalperstatus?bookkeepingId=${bookkeepingId}&period=${period}&selectionStart=${selectionStart}&selectionEnd=${selectionEnd}`, requestSizeLimit: 3145728 }
            ),
            GetTotalsPerOwner: Object.assign(
                (bookkeepingId: number, period: server.dto.PeriodFilterOptions, selectionStart: number, selectionEnd: number) => api.get<server.dto.TotalsPerOwner>(`/api/v1/purchaseagreements/stats/totalperowner`, { params: { bookkeepingId, period, selectionStart, selectionEnd } }),
                { absoluteUrl: (bookkeepingId: number, period: server.dto.PeriodFilterOptions, selectionStart: number, selectionEnd: number) => `${baseURL}/api/v1/purchaseagreements/stats/totalperowner?bookkeepingId=${bookkeepingId}&period=${period}&selectionStart=${selectionStart}&selectionEnd=${selectionEnd}`, requestSizeLimit: 3145728 }
            ),
            GetEmails: Object.assign(
                (purchaseAgreementId: number, page: number, per_page: number) => api.get<server.dto.PaginationWrapper<server.dto.EmailMessage[]>>(`/api/v1/purchaseagreements/${purchaseAgreementId}/emails`, { params: { page, per_page } }),
                { absoluteUrl: (purchaseAgreementId: number, page: number, per_page: number) => `${baseURL}/api/v1/purchaseagreements/${purchaseAgreementId}/emails?page=${page}&per_page=${per_page}`, requestSizeLimit: 3145728 }
            ),
            ListEmailTemplates: Object.assign(
                () => api.get<server.dto.KeyValueSublabel[]>(`/api/v1/purchaseagreements/emails/templates`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/purchaseagreements/emails/templates`, requestSizeLimit: 3145728 }
            ),
            FilesGet: Object.assign(
                (purchaseAgreementId: number, page: number, per_page: number) => api.get<server.dto.PaginationWrapper<server.dto.File[]>>(`/api/v1/purchaseagreements/${purchaseAgreementId}/files`, { params: { page, per_page } }),
                { absoluteUrl: (purchaseAgreementId: number, page: number, per_page: number) => `${baseURL}/api/v1/purchaseagreements/${purchaseAgreementId}/files?page=${page}&per_page=${per_page}`, requestSizeLimit: 3145728 }
            ),
            ProductsGet: Object.assign(
                (purchaseAgreementId: number) => api.get<server.dto.ProductsByParent>(`/api/v1/purchaseagreements/${purchaseAgreementId}/products`, undefined),
                { absoluteUrl: (purchaseAgreementId: number) => `${baseURL}/api/v1/purchaseagreements/${purchaseAgreementId}/products`, requestSizeLimit: 3145728 }
            ),
            ProductsUpdateSort: Object.assign(
                (purchaseAgreementId: number, ids: number[]) => api.post<void>(`/api/v1/purchaseagreements/${purchaseAgreementId}/products/sort`, undefined, { params: { ids } }),
                { absoluteUrl: (purchaseAgreementId: number, ids: number[]) => `${baseURL}/api/v1/purchaseagreements/${purchaseAgreementId}/products/sort?ids=${ids}`, requestSizeLimit: 3145728 }
            ),
            ProductCreate: Object.assign(
                (purchaseAgreementId: number, data: server.dto.ProductPost) => api.put<server.dto.ApiResponseWrapper<server.dto.ProductsByParent>>(`/api/v1/purchaseagreements/${purchaseAgreementId}/products`, data, undefined),
                { absoluteUrl: (purchaseAgreementId: number) => `${baseURL}/api/v1/purchaseagreements/${purchaseAgreementId}/products`, requestSizeLimit: 3145728 }
            ),
            ProductUpdate: Object.assign(
                (purchaseAgreementId: number, productId: number, data: server.dto.ProductPost) => api.post<server.dto.ApiResponseWrapper<server.dto.ProductsByParent>>(`/api/v1/purchaseagreements/${purchaseAgreementId}/products/${productId}`, data, undefined),
                { absoluteUrl: (purchaseAgreementId: number, productId: number) => `${baseURL}/api/v1/purchaseagreements/${purchaseAgreementId}/products/${productId}`, requestSizeLimit: 3145728 }
            ),
            ProductDelete: Object.assign(
                (purchaseAgreementId: number, productId: number) => api.delete(`/api/v1/purchaseagreements/${purchaseAgreementId}/products/${productId}`, undefined),
                { absoluteUrl: (purchaseAgreementId: number, productId: number) => `${baseURL}/api/v1/purchaseagreements/${purchaseAgreementId}/products/${productId}`, requestSizeLimit: 3145728 }
            ),
            ListRelatedFiles: Object.assign(
                (purchaseAgreementId: number) => api.get<server.dto.RelatedFiles>(`/api/v1/purchaseagreements/${purchaseAgreementId}/files/related`, undefined),
                { absoluteUrl: (purchaseAgreementId: number) => `${baseURL}/api/v1/purchaseagreements/${purchaseAgreementId}/files/related`, requestSizeLimit: 3145728 }
            )
        },
        Quotes: {
            Copy: Object.assign(
                (quoteId: number, data: server.dto.CopyDocumentInstruction) => api.put<server.dto.Document>(`/api/v1/quotes/${quoteId}/copy`, data, undefined),
                { absoluteUrl: (quoteId: number) => `${baseURL}/api/v1/quotes/${quoteId}/copy`, requestSizeLimit: 3145728 }
            ),
            Update: Object.assign(
                (quoteId: number, quote: server.dto.ModifyDocumentInstruction) => api.post<server.dto.Document>(`/api/v1/quotes/${quoteId}`, quote, undefined),
                { absoluteUrl: (quoteId: number) => `${baseURL}/api/v1/quotes/${quoteId}`, requestSizeLimit: 3145728 }
            ),
            Create: Object.assign(
                (quote: server.dto.ModifyDocumentInstruction) => api.put<server.dto.Document>(`/api/v1/quotes`, quote, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/quotes`, requestSizeLimit: 3145728 }
            ),
            LinkShows: Object.assign(
                (quoteId: number, data: server.dto.LinkShowInstruction[]) => api.put<any>(`/api/v1/quotes/${quoteId}/shows/link`, data, undefined),
                { absoluteUrl: (quoteId: number) => `${baseURL}/api/v1/quotes/${quoteId}/shows/link`, requestSizeLimit: 3145728 }
            ),
            UnlinkShow: Object.assign(
                (quoteId: number, showId: number) => api.put<server.dto.ShowsByParent>(`/api/v1/quotes/${quoteId}/shows/${showId}/unlink`, undefined, undefined),
                { absoluteUrl: (quoteId: number, showId: number) => `${baseURL}/api/v1/quotes/${quoteId}/shows/${showId}/unlink`, requestSizeLimit: 3145728 }
            ),
            GetTotalsData: Object.assign(
                (bookkeepingId: number, period: server.dto.GroupByPeriodOptions) => api.get<server.dto.GroupedDocumentTotalsResult>(`/api/v1/quotes/stats/totalsdata`, { params: { bookkeepingId, period } }),
                { absoluteUrl: (bookkeepingId: number, period: server.dto.GroupByPeriodOptions) => `${baseURL}/api/v1/quotes/stats/totalsdata?bookkeepingId=${bookkeepingId}&period=${period}`, requestSizeLimit: 3145728 }
            ),
            GetTotalsPerStatus: Object.assign(
                (bookkeepingId: number, period: server.dto.PeriodFilterOptions, selectionStart: number, selectionEnd: number) => api.get<server.dto.TotalsPerStatus>(`/api/v1/quotes/stats/totalperstatus`, { params: { bookkeepingId, period, selectionStart, selectionEnd } }),
                { absoluteUrl: (bookkeepingId: number, period: server.dto.PeriodFilterOptions, selectionStart: number, selectionEnd: number) => `${baseURL}/api/v1/quotes/stats/totalperstatus?bookkeepingId=${bookkeepingId}&period=${period}&selectionStart=${selectionStart}&selectionEnd=${selectionEnd}`, requestSizeLimit: 3145728 }
            ),
            GetTotalsPerOwner: Object.assign(
                (bookkeepingId: number, period: server.dto.PeriodFilterOptions, selectionStart: number, selectionEnd: number) => api.get<server.dto.TotalsPerOwner>(`/api/v1/quotes/stats/totalperowner`, { params: { bookkeepingId, period, selectionStart, selectionEnd } }),
                { absoluteUrl: (bookkeepingId: number, period: server.dto.PeriodFilterOptions, selectionStart: number, selectionEnd: number) => `${baseURL}/api/v1/quotes/stats/totalperowner?bookkeepingId=${bookkeepingId}&period=${period}&selectionStart=${selectionStart}&selectionEnd=${selectionEnd}`, requestSizeLimit: 3145728 }
            ),
            GetEmails: Object.assign(
                (quoteId: number, page: number, per_page: number) => api.get<server.dto.PaginationWrapper<server.dto.EmailMessage[]>>(`/api/v1/quotes/${quoteId}/emails`, { params: { page, per_page } }),
                { absoluteUrl: (quoteId: number, page: number, per_page: number) => `${baseURL}/api/v1/quotes/${quoteId}/emails?page=${page}&per_page=${per_page}`, requestSizeLimit: 3145728 }
            ),
            ListEmailTemplates: Object.assign(
                () => api.get<server.dto.KeyValueSublabel[]>(`/api/v1/quotes/emails/templates`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/quotes/emails/templates`, requestSizeLimit: 3145728 }
            ),
            FilesGet: Object.assign(
                (quoteId: number, page: number, per_page: number) => api.get<server.dto.PaginationWrapper<server.dto.File[]>>(`/api/v1/quotes/${quoteId}/files`, { params: { page, per_page } }),
                { absoluteUrl: (quoteId: number, page: number, per_page: number) => `${baseURL}/api/v1/quotes/${quoteId}/files?page=${page}&per_page=${per_page}`, requestSizeLimit: 3145728 }
            ),
            ProductsGet: Object.assign(
                (quoteId: number) => api.get<server.dto.ProductsByParent>(`/api/v1/quotes/${quoteId}/products`, undefined),
                { absoluteUrl: (quoteId: number) => `${baseURL}/api/v1/quotes/${quoteId}/products`, requestSizeLimit: 3145728 }
            ),
            ProductsUpdateSort: Object.assign(
                (quoteId: number, ids: number[]) => api.post<void>(`/api/v1/quotes/${quoteId}/products/sort`, undefined, { params: { ids } }),
                { absoluteUrl: (quoteId: number, ids: number[]) => `${baseURL}/api/v1/quotes/${quoteId}/products/sort?ids=${ids}`, requestSizeLimit: 3145728 }
            ),
            ProductCreate: Object.assign(
                (quoteId: number, data: server.dto.ProductPost) => api.put<server.dto.ApiResponseWrapper<server.dto.ProductsByParent>>(`/api/v1/quotes/${quoteId}/products`, data, undefined),
                { absoluteUrl: (quoteId: number) => `${baseURL}/api/v1/quotes/${quoteId}/products`, requestSizeLimit: 3145728 }
            ),
            ProductUpdate: Object.assign(
                (quoteId: number, productId: number, data: server.dto.ProductPost) => api.post<server.dto.ApiResponseWrapper<server.dto.ProductsByParent>>(`/api/v1/quotes/${quoteId}/products/${productId}`, data, undefined),
                { absoluteUrl: (quoteId: number, productId: number) => `${baseURL}/api/v1/quotes/${quoteId}/products/${productId}`, requestSizeLimit: 3145728 }
            ),
            ProductDelete: Object.assign(
                (quoteId: number, productId: number) => api.delete(`/api/v1/quotes/${quoteId}/products/${productId}`, undefined),
                { absoluteUrl: (quoteId: number, productId: number) => `${baseURL}/api/v1/quotes/${quoteId}/products/${productId}`, requestSizeLimit: 3145728 }
            ),
            ListRelatedFiles: Object.assign(
                (quoteId: number) => api.get<server.dto.RelatedFiles>(`/api/v1/quotes/${quoteId}/files/related`, undefined),
                { absoluteUrl: (quoteId: number) => `${baseURL}/api/v1/quotes/${quoteId}/files/related`, requestSizeLimit: 3145728 }
            )
        },
        Register: {
            DeleteByPns: Object.assign(
                (pns: any) => api.delete(`/api/v1/register/pns`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/register/pns`, requestSizeLimit: 3145728 }
            )
        },
        Schedule: {
            PeekItineraryExport: Object.assign(
                (start: Date, end: Date, phases: server.dto.AppointmentPhases[], types: server.dto.AppointmentTypes[], artistIds: number[], contactIds: number[]) => api.get<server.dto.ApiResponseWrapperWithRequest<server.dto.PeekItineraryExportResult, server.dto.ItineraryExportRequest>>(`/api/v1/schedule/itinerary/export/peek`, { params: { start, end, phases, types, artistIds, contactIds } }),
                { absoluteUrl: (start: Date, end: Date, phases: server.dto.AppointmentPhases[], types: server.dto.AppointmentTypes[], artistIds: number[], contactIds: number[]) => `${baseURL}/api/v1/schedule/itinerary/export/peek?start=${start}&end=${end}&phases=${phases}&types=${types}&artistIds=${artistIds}&contactIds=${contactIds}`, requestSizeLimit: 3145728 }
            )
        },
        Shows: {
            GetEmails: Object.assign(
                (showId: number, page: number, per_page: number) => api.get<server.dto.PaginationWrapper<server.dto.EmailMessage[]>>(`/api/v1/shows/${showId}/emails`, { params: { page, per_page } }),
                { absoluteUrl: (showId: number, page: number, per_page: number) => `${baseURL}/api/v1/shows/${showId}/emails?page=${page}&per_page=${per_page}`, requestSizeLimit: 3145728 }
            ),
            ListEmailTemplates: Object.assign(
                () => api.get<server.dto.KeyValueSublabel[]>(`/api/v1/shows/emails/templates`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/shows/emails/templates`, requestSizeLimit: 3145728 }
            ),
            FilesGet: Object.assign(
                (showId: number, page: number, per_page: number) => api.get<server.dto.PaginationWrapper<server.dto.File[]>>(`/api/v1/shows/${showId}/files`, { params: { page, per_page } }),
                { absoluteUrl: (showId: number, page: number, per_page: number) => `${baseURL}/api/v1/shows/${showId}/files?page=${page}&per_page=${per_page}`, requestSizeLimit: 3145728 }
            ),
            ProductsGet: Object.assign(
                (showId: number) => api.get<server.dto.ProductsByParent>(`/api/v1/shows/${showId}/products`, undefined),
                { absoluteUrl: (showId: number) => `${baseURL}/api/v1/shows/${showId}/products`, requestSizeLimit: 3145728 }
            ),
            ProductsUpdateSort: Object.assign(
                (showId: number, ids: number[]) => api.post<void>(`/api/v1/shows/${showId}/products/sort`, undefined, { params: { ids } }),
                { absoluteUrl: (showId: number, ids: number[]) => `${baseURL}/api/v1/shows/${showId}/products/sort?ids=${ids}`, requestSizeLimit: 3145728 }
            ),
            ProductCreate: Object.assign(
                (showId: number, data: server.dto.ProductPost) => api.put<server.dto.ApiResponseWrapper<server.dto.ProductsByParent>>(`/api/v1/shows/${showId}/products`, data, undefined),
                { absoluteUrl: (showId: number) => `${baseURL}/api/v1/shows/${showId}/products`, requestSizeLimit: 3145728 }
            ),
            ProductUpdate: Object.assign(
                (showId: number, productId: number, data: server.dto.ProductPost) => api.post<server.dto.ApiResponseWrapper<server.dto.ProductsByParent>>(`/api/v1/shows/${showId}/products/${productId}`, data, undefined),
                { absoluteUrl: (showId: number, productId: number) => `${baseURL}/api/v1/shows/${showId}/products/${productId}`, requestSizeLimit: 3145728 }
            ),
            ProductDelete: Object.assign(
                (showId: number, productId: number) => api.delete(`/api/v1/shows/${showId}/products/${productId}`, undefined),
                { absoluteUrl: (showId: number, productId: number) => `${baseURL}/api/v1/shows/${showId}/products/${productId}`, requestSizeLimit: 3145728 }
            ),
            GetLegacyShow: Object.assign(
                (showId: number) => api.get<any>(`/api/v1/shows/${showId}`, undefined),
                { absoluteUrl: (showId: number) => `${baseURL}/api/v1/shows/${showId}`, requestSizeLimit: 3145728 }
            ),
            Approve: Object.assign(
                (showId: number) => api.post<any>(`/api/v1/shows/${showId}`, undefined, undefined),
                { absoluteUrl: (showId: number) => `${baseURL}/api/v1/shows/${showId}`, requestSizeLimit: 3145728 }
            ),
            Lock: Object.assign(
                (showId: number) => api.post<any>(`/api/v1/shows/${showId}/lock`, undefined, undefined),
                { absoluteUrl: (showId: number) => `${baseURL}/api/v1/shows/${showId}/lock`, requestSizeLimit: 3145728 }
            ),
            Unlock: Object.assign(
                (showId: number) => api.post<any>(`/api/v1/shows/${showId}/unlock`, undefined, undefined),
                { absoluteUrl: (showId: number) => `${baseURL}/api/v1/shows/${showId}/unlock`, requestSizeLimit: 3145728 }
            ),
            LinkFlightSegment: Object.assign(
                (showId: number, flightSegmentId: number) => api.get<any>(`/api/v1/shows/${showId}/flightsegment/link`, { params: { flightSegmentId } }),
                { absoluteUrl: (showId: number, flightSegmentId: number) => `${baseURL}/api/v1/shows/${showId}/flightsegment/link?flightSegmentId=${flightSegmentId}`, requestSizeLimit: 3145728 }
            ),
            UnlinkFlightSegment: Object.assign(
                (showId: number, flightSegmentId: number) => api.get<any>(`/api/v1/shows/${showId}/flightsegment/unlink`, { params: { flightSegmentId } }),
                { absoluteUrl: (showId: number, flightSegmentId: number) => `${baseURL}/api/v1/shows/${showId}/flightsegment/unlink?flightSegmentId=${flightSegmentId}`, requestSizeLimit: 3145728 }
            ),
            LinkTrainSegment: Object.assign(
                (showId: number, trainSegmentId: number) => api.get<any>(`/api/v1/shows/${showId}/trainsegment/link`, { params: { trainSegmentId } }),
                { absoluteUrl: (showId: number, trainSegmentId: number) => `${baseURL}/api/v1/shows/${showId}/trainsegment/link?trainSegmentId=${trainSegmentId}`, requestSizeLimit: 3145728 }
            ),
            UnlinkTrainSegment: Object.assign(
                (showId: number, trainSegmentId: number) => api.get<any>(`/api/v1/shows/${showId}/trainsegment/unlink`, { params: { trainSegmentId } }),
                { absoluteUrl: (showId: number, trainSegmentId: number) => `${baseURL}/api/v1/shows/${showId}/trainsegment/unlink?trainSegmentId=${trainSegmentId}`, requestSizeLimit: 3145728 }
            ),
            LinkGroundTransport: Object.assign(
                (showId: number, groundTransportId: number) => api.get<any>(`/api/v1/shows/${showId}/groundTransport/link`, { params: { groundTransportId } }),
                { absoluteUrl: (showId: number, groundTransportId: number) => `${baseURL}/api/v1/shows/${showId}/groundTransport/link?groundTransportId=${groundTransportId}`, requestSizeLimit: 3145728 }
            ),
            UnlinkGroundTransport: Object.assign(
                (showId: number, groundTransportId: number) => api.get<any>(`/api/v1/shows/${showId}/groundTransport/unlink`, { params: { groundTransportId } }),
                { absoluteUrl: (showId: number, groundTransportId: number) => `${baseURL}/api/v1/shows/${showId}/groundTransport/unlink?groundTransportId=${groundTransportId}`, requestSizeLimit: 3145728 }
            ),
            DeleteExclusivityClause: Object.assign(
                (showId: number) => api.delete(`/api/v1/shows/${showId}/exclusivity/delete`, undefined),
                { absoluteUrl: (showId: number) => `${baseURL}/api/v1/shows/${showId}/exclusivity/delete`, requestSizeLimit: 3145728 }
            ),
            SetExclusivityClause: Object.assign(
                (dto: server.dto.SetExclusivityClauseInstruction) => api.post<server.dto.ExclusivityClause>(`/api/v1/shows/exclusivity/set`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/shows/exclusivity/set`, requestSizeLimit: 3145728 }
            ),
            ListExclusivityConflictsWithShow: Object.assign(
                (showId: number) => api.get<server.dto.ListExclusivityConflictWithShowOutcome>(`/api/v1/shows/${showId}/exclusivityconflicts`, undefined),
                { absoluteUrl: (showId: number) => `${baseURL}/api/v1/shows/${showId}/exclusivityconflicts`, requestSizeLimit: 3145728 }
            ),
            ListShowConflict: Object.assign(
                (dto: server.dto.ListShowConflictInstruction) => api.post<server.dto.ListShowConflictOutcome>(`/api/v1/shows/showconflict/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/shows/showconflict/list`, requestSizeLimit: 3145728 }
            ),
            GetLinkedTravel: Object.assign(
                (showid: number) => api.get<server.dto.LinkedTravel>(`/api/v1/shows/${showid}/linkedtravel`, undefined),
                { absoluteUrl: (showid: number) => `${baseURL}/api/v1/shows/${showid}/linkedtravel`, requestSizeLimit: 3145728 }
            ),
            GetGroupedData: Object.assign(
                (request: server.dto.GroupedShowTotalsRequest) => api.post<server.dto.GroupedShowTotalsResult>(`/api/v1/shows/totals`, request, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/shows/totals`, requestSizeLimit: 3145728 }
            ),
            CreateDocument: Object.assign(
                (dto: server.dto.CreateDocumentFromShowInstruction) => api.post<string>(`/api/v1/shows/createDocument`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/shows/createDocument`, requestSizeLimit: 3145728 }
            ),
            CreateInvoice: Object.assign(
                (dto: server.dto.CreateInvoiceFromShowInstruction) => api.post<string>(`/api/v1/shows/createInvoice`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/shows/createInvoice`, requestSizeLimit: 3145728 }
            ),
            ListRelatedFiles: Object.assign(
                (showId: number) => api.get<server.dto.RelatedFiles>(`/api/v1/shows/${showId}/files/related`, undefined),
                { absoluteUrl: (showId: number) => `${baseURL}/api/v1/shows/${showId}/files/related`, requestSizeLimit: 3145728 }
            ),
            ListShowsWithProducts: Object.assign(
                (showIds: number[]) => api.get<server.dto.ShowWithProducts[]>(`/api/v1/shows/showswithproducts`, { params: { showIds } }),
                { absoluteUrl: (showIds: number[]) => `${baseURL}/api/v1/shows/showswithproducts?showIds=${showIds}`, requestSizeLimit: 3145728 }
            )
        },
        Tests: {
            HelloWorld: Object.assign(
                () => api.get<string>(`/api/v1/tests/hi`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/tests/hi`, requestSizeLimit: 3145728 }
            ),
            TestException: Object.assign(
                () => api.get<string>(`/api/v1/tests/exception/sync`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/tests/exception/sync`, requestSizeLimit: 3145728 }
            ),
            TestExceptionAsync: Object.assign(
                () => api.get<any>(`/api/v1/tests/exception/async`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/tests/exception/async`, requestSizeLimit: 3145728 }
            ),
            TestExceptionInMediator: Object.assign(
                () => api.get<string>(`/api/v1/tests/exception/mediator`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/tests/exception/mediator`, requestSizeLimit: 3145728 }
            )
        },
        Trains: {
            GetTrain: Object.assign(
                (trainId: number) => api.get<server.dto.Train>(`/api/v1/trains/${trainId}`, undefined),
                { absoluteUrl: (trainId: number) => `${baseURL}/api/v1/trains/${trainId}`, requestSizeLimit: 3145728 }
            ),
            CreateTrain: Object.assign(
                (dto: server.dto.CreateTrainInstruction) => api.post<server.dto.Train>(`/api/v1/trains/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/trains/create`, requestSizeLimit: 3145728 }
            ),
            UpdateTrainDetails: Object.assign(
                (trainId: number, dto: server.dto.ModifyTrainDetailsInstruction) => api.put<server.dto.Train>(`/api/v1/trains/${trainId}/update`, dto, undefined),
                { absoluteUrl: (trainId: number) => `${baseURL}/api/v1/trains/${trainId}/update`, requestSizeLimit: 3145728 }
            ),
            Delete: Object.assign(
                (trainId: number) => api.delete(`/api/v1/trains/${trainId}`, undefined),
                { absoluteUrl: (trainId: number) => `${baseURL}/api/v1/trains/${trainId}`, requestSizeLimit: 3145728 }
            ),
            CopyTrain: Object.assign(
                (trainId: number) => api.put<number>(`/api/v1/trains/${trainId}/copy`, undefined, undefined),
                { absoluteUrl: (trainId: number) => `${baseURL}/api/v1/trains/${trainId}/copy`, requestSizeLimit: 3145728 }
            ),
            ListTrainFiles: Object.assign(
                (trainId: number) => api.get<server.dto.File[]>(`/api/v1/trains/${trainId}/files`, undefined),
                { absoluteUrl: (trainId: number) => `${baseURL}/api/v1/trains/${trainId}/files`, requestSizeLimit: 3145728 }
            ),
            GetTrainBySegment: Object.assign(
                (trainSegmentId: number) => api.get<server.dto.Train>(`/api/v1/trains/segments/${trainSegmentId}`, undefined),
                { absoluteUrl: (trainSegmentId: number) => `${baseURL}/api/v1/trains/segments/${trainSegmentId}`, requestSizeLimit: 3145728 }
            ),
            CreateSegment: Object.assign(
                (trainId: number, dto: server.dto.ModifyTrainSegmentInstruction) => api.put<server.dto.Train>(`/api/v1/trains/${trainId}/segments/create`, dto, undefined),
                { absoluteUrl: (trainId: number) => `${baseURL}/api/v1/trains/${trainId}/segments/create`, requestSizeLimit: 3145728 }
            ),
            UpdateSegment: Object.assign(
                (trainSegmentId: number, dto: server.dto.ModifyTrainSegmentInstruction) => api.post<server.dto.Train>(`/api/v1/trains/segments/${trainSegmentId}/update`, dto, undefined),
                { absoluteUrl: (trainSegmentId: number) => `${baseURL}/api/v1/trains/segments/${trainSegmentId}/update`, requestSizeLimit: 3145728 }
            ),
            DeleteSegment: Object.assign(
                (trainSegmentId: number) => api.delete(`/api/v1/trains/segments/${trainSegmentId}`, undefined),
                { absoluteUrl: (trainSegmentId: number) => `${baseURL}/api/v1/trains/segments/${trainSegmentId}`, requestSizeLimit: 3145728 }
            ),
            GetToolTip: Object.assign(
                (trainSegmentId: number) => api.get<server.dto.Tooltip>(`/api/v1/trains/segments/${trainSegmentId}/tooltip`, undefined),
                { absoluteUrl: (trainSegmentId: number) => `${baseURL}/api/v1/trains/segments/${trainSegmentId}/tooltip`, requestSizeLimit: 3145728 }
            ),
            GetTrainSegment: Object.assign(
                (trainId: number, trainSegmentId: number) => api.get<any>(`/api/v1/trains/${trainId}/segments/${trainSegmentId}`, undefined),
                { absoluteUrl: (trainId: number, trainSegmentId: number) => `${baseURL}/api/v1/trains/${trainId}/segments/${trainSegmentId}`, requestSizeLimit: 3145728 }
            ),
            GetTrainStation: Object.assign(
                (placeId: string) => api.get<server.dto.TrainStation>(`/api/v1/trains/trainstation`, { params: { placeId } }),
                { absoluteUrl: (placeId: string) => `${baseURL}/api/v1/trains/trainstation?placeId=${placeId}`, requestSizeLimit: 3145728 }
            )
        },
        Users: {
            ListArtists: Object.assign(
                (userId: number, environment: server.dto.Environments) => api.get<server.dto.ArtistSmall[]>(`/api/v1/users/${userId}/${environment}/artists`, undefined),
                { absoluteUrl: (userId: number, environment: server.dto.Environments) => `${baseURL}/api/v1/users/${userId}/${environment}/artists`, requestSizeLimit: 3145728 }
            ),
            InsertArtistLink: Object.assign(
                (userId: number, environment: server.dto.Environments, artistId: number) => api.put<any>(`/api/v1/users/${userId}/${environment}/artists/${artistId}`, undefined, undefined),
                { absoluteUrl: (userId: number, environment: server.dto.Environments, artistId: number) => `${baseURL}/api/v1/users/${userId}/${environment}/artists/${artistId}`, requestSizeLimit: 3145728 }
            ),
            DeleteArtistLink: Object.assign(
                (userId: number, environment: server.dto.Environments, artistId: number) => api.delete(`/api/v1/users/${userId}/${environment}/artists/${artistId}`, undefined),
                { absoluteUrl: (userId: number, environment: server.dto.Environments, artistId: number) => `${baseURL}/api/v1/users/${userId}/${environment}/artists/${artistId}`, requestSizeLimit: 3145728 }
            ),
            GetDetails: Object.assign(
                (userId: number) => api.get<server.dto.User>(`/api/v1/users/${userId}`, undefined),
                { absoluteUrl: (userId: number) => `${baseURL}/api/v1/users/${userId}`, requestSizeLimit: 3145728 }
            ),
            GetCurrentUser: Object.assign(
                () => api.get<any>(`/api/v1/users/user`, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/users/user`, requestSizeLimit: 3145728 }
            ),
            GetUserUsage: Object.assign(
                (userId: number) => api.get<server.dto.UserUsage>(`/api/v1/users/${userId}/usage`, undefined),
                { absoluteUrl: (userId: number) => `${baseURL}/api/v1/users/${userId}/usage`, requestSizeLimit: 3145728 }
            ),
            TransferUserUsage: Object.assign(
                (sourceUserId: number, dto: server.dto.TransferUserUsageInstruction) => api.put<server.dto.TransferUserUsageResult>(`/api/v1/users/${sourceUserId}/usage/transfer`, dto, undefined),
                { absoluteUrl: (sourceUserId: number) => `${baseURL}/api/v1/users/${sourceUserId}/usage/transfer`, requestSizeLimit: 3145728 }
            ),
            SetUserFreeFieldProfile: Object.assign(
                (userId: number, freeFieldProfileId: number) => api.put<server.dto.FreeFieldProfile>(`/api/v1/users/${userId}/freefieldprofile`, undefined, { params: { freeFieldProfileId } }),
                { absoluteUrl: (userId: number, freeFieldProfileId: number) => `${baseURL}/api/v1/users/${userId}/freefieldprofile?freeFieldProfileId=${freeFieldProfileId}`, requestSizeLimit: 3145728 }
            ),
            UpdateDefaultBookkeeping: Object.assign(
                (userId: number, environment: server.dto.Environments, bookkeepingId: number) => api.put<any>(`/api/v1/users/${userId}/${environment}/defaultbookkeeping`, undefined, { params: { bookkeepingId } }),
                { absoluteUrl: (userId: number, environment: server.dto.Environments, bookkeepingId: number) => `${baseURL}/api/v1/users/${userId}/${environment}/defaultbookkeeping?bookkeepingId=${bookkeepingId}`, requestSizeLimit: 3145728 }
            ),
            UpdateFreeFieldProfileForUsers: Object.assign(
                (instruction: server.dto.AssignUsersToFreeFieldProfileInstruction) => api.post<server.dto.UserSmall[]>(`/api/v1/users/freefieldprofile`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/api/v1/users/freefieldprofile`, requestSizeLimit: 3145728 }
            ),
            StoreBookkeepingsToUser: Object.assign(
                (userId: number, bookkeepingIds: number[]) => api.put<any>(`/api/v1/users/internal/${userId}/bookkeepings/store`, bookkeepingIds, undefined),
                { absoluteUrl: (userId: number) => `${baseURL}/api/v1/users/internal/${userId}/bookkeepings/store`, requestSizeLimit: 3145728 }
            ),
            ConnectSocial: Object.assign(
                (issuer: string, token: string) => api.get<any>(`/api/v1/users/user/sociallogin`, { params: { issuer, token } }),
                { absoluteUrl: (issuer: string, token: string) => `${baseURL}/api/v1/users/user/sociallogin?issuer=${issuer}&token=${token}`, requestSizeLimit: 3145728 }
            ),
            UpdateAvatar: Object.assign(
                (userId: number) => api.post<server.dto.FileUploadResult>(`/api/v1/users/${userId}/avatar`, undefined, undefined),
                { absoluteUrl: (userId: number) => `${baseURL}/api/v1/users/${userId}/avatar`, requestSizeLimit: 3145728 }
            )
        },
        UserTasks: {
            ByUser: Object.assign(
                (local: Date) => api.get<server.dto.ApiResponseWrapper<server.dto.TasksByUser[]>>(`/api/v1/tasks/byuser`, { params: { local } }),
                { absoluteUrl: (local: Date) => `${baseURL}/api/v1/tasks/byuser?local=${local}`, requestSizeLimit: 3145728 }
            )
        },
        Venues: {
            GetDetails: Object.assign(
                (venueId: number) => api.get<any>(`/api/v1/venues/${venueId}`, undefined),
                { absoluteUrl: (venueId: number) => `${baseURL}/api/v1/venues/${venueId}`, requestSizeLimit: 3145728 }
            )
        },
        Webforms: {
            Delete: Object.assign(
                (webformId: number) => api.delete(`/api/v1/webforms/${webformId}`, undefined),
                { absoluteUrl: (webformId: number) => `${baseURL}/api/v1/webforms/${webformId}`, requestSizeLimit: 3145728 }
            ),
            ListArtists: Object.assign(
                (webformId: number) => api.get<server.dto.ArtistSmall[]>(`/api/v1/webforms/${webformId}/artists`, undefined),
                { absoluteUrl: (webformId: number) => `${baseURL}/api/v1/webforms/${webformId}/artists`, requestSizeLimit: 3145728 }
            ),
            InsertArtistLink: Object.assign(
                (webformId: number, artistId: number) => api.put<any>(`/api/v1/webforms/${webformId}/artists/${artistId}`, undefined, undefined),
                { absoluteUrl: (webformId: number, artistId: number) => `${baseURL}/api/v1/webforms/${webformId}/artists/${artistId}`, requestSizeLimit: 3145728 }
            ),
            DeleteArtistLink: Object.assign(
                (webformId: number, artistId: number) => api.delete(`/api/v1/webforms/${webformId}/artists/${artistId}`, undefined),
                { absoluteUrl: (webformId: number, artistId: number) => `${baseURL}/api/v1/webforms/${webformId}/artists/${artistId}`, requestSizeLimit: 3145728 }
            ),
            UpdateCSS: Object.assign(
                (webformId: number, value: string) => api.post<any>(`/api/v1/webforms/${webformId}/css`, value, undefined),
                { absoluteUrl: (webformId: number) => `${baseURL}/api/v1/webforms/${webformId}/css`, requestSizeLimit: 3145728 }
            ),
            StoreFreeFieldForWebform: Object.assign(
                (webformId: number, freeFieldDefinitionId: number, isMandatory: boolean) => api.put<server.dto.Webform>(`/api/v1/webforms/${webformId}/freefields/${freeFieldDefinitionId}`, undefined, { params: { isMandatory } }),
                { absoluteUrl: (webformId: number, freeFieldDefinitionId: number, isMandatory: boolean) => `${baseURL}/api/v1/webforms/${webformId}/freefields/${freeFieldDefinitionId}?isMandatory=${isMandatory}`, requestSizeLimit: 3145728 }
            ),
            DeleteFreeFieldForWebform: Object.assign(
                (webformId: number, freeFieldDefinitionId: number) => api.delete(`/api/v1/webforms/${webformId}/freefields/${freeFieldDefinitionId}`, undefined),
                { absoluteUrl: (webformId: number, freeFieldDefinitionId: number) => `${baseURL}/api/v1/webforms/${webformId}/freefields/${freeFieldDefinitionId}`, requestSizeLimit: 3145728 }
            ),
            StoreFreeFieldForWebformSort: Object.assign(
                (webformId: number, freefieldDefinitionIds: number[]) => api.post<server.dto.Webform>(`/api/v1/webforms/${webformId}/freefields/sort`, freefieldDefinitionIds, undefined),
                { absoluteUrl: (webformId: number) => `${baseURL}/api/v1/webforms/${webformId}/freefields/sort`, requestSizeLimit: 3145728 }
            )
        },
    };
    (window as any).Api = apiFunctions;
    return apiFunctions;
}
export { createApiFunctions };